.main {
    background-color: #25aaf7;
    padding: 20px;
    margin: 60px auto 0 auto;
  }
  .sidebar {
    border-radius: 5px;
  }
  .sidebar img {
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }
  .sidebar div {
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .sidebar ul li {
    text-align: left;
  }
  .content {
    border-radius: 5px;
  }
  .services img {
    width: 200px;
    height: 200px;
  }
  .services .col-service {
    width: 100%;
    text-align: center;
  }
  .services .col-service p {
    font-weight: bold;
  }